// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Regular font family
$font__regular: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

/// Compact font family
$font__compact: 'Gilroy', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

/// Code (monospace) font family
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace;


/// Copy text color
$text-color: rgb(34, 34, 34);

/// Main brand color
$brand-color: #9D1C1F;
$brand-color-dark: #760303;

$brand-redline: #9D1C1F;
$brand-redline-bak: #9C0404;
$brand-redline-dark: #760303;
$brand-redline-light: #ffefef;

/// Secondary colors
$baby-brand: #FDEDE9;
$slate-blue: #212b38;
$baby-slate: #E0EDFF;
$beach-sand: #FDEBC9;
$beach-sand-light: #FFF9ED;
$baby-orange: #fffaf4;

/// Greys
$light-grey: #F4F4F4;
$mid-grey: rgb(153, 153, 153);
$dark-grey: rgb(68, 68, 68);

/// Rando
$grey-blue: #bec8cb;
$grey-blue2: #c7d2e3;
$baby-blue: #d9e3f1;
$baby-slate2: #768291;
$accent-color-light: #c7d2e3;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
);


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/';
