// -----------------------------------------------------------------------------
// Dropdown menus
// -----------------------------------------------------------------------------

.dropdown-menu{
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border-color: $brand-color;
}
.dropdown-header {
  font-family: $font__regular;
  font-size: 18px;
  font-weight: 300;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: $brand-color;
}
.dropdown-menu > li > a{
  padding: 6px 20px;
  font-family: $font__regular;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus{
  background-color: $brand-color;
  color: #fff;
}
.dropdown-menu hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 15px;
  }
}
