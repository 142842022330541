

.flex {
  display: flex;
}
.flex-center {
  flex:1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-col {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.flex-content {
  flex: 1;
}
.flex-start {
  align-content: flex-start;
}
.flex-end {
  align-content: flex-end;
}
.space-between {
  align-content: space-between;
}
.space-around { 
  align-content: space-around;
}

.grid-thing {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
} 
.grid-thing.six {
  grid-template-columns: repeat(6, 1fr);
}
.grid-thing.two {
  grid-template-columns: repeat(2, 1fr);
}
.grid-thing.three {
  grid-template-columns: repeat(3, 1fr);
}  

.grid-container {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 160px) !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.grid-container div {
  max-width: 100%;
  height: auto;
  display: block;
  text-align: center;
  font-size: 2em;
  background-color: #000;
  padding: 60px 0 !important;
}
.grid-container a {
  color: #fff;
}
.grid-container div:focus,
.grid-container div:hover {
  background-color: #9C0404;
}

@media (max-width: 767px){
  .flex.xs-reset {
    display: block;
  }
  .flex.xs-reset .px-10 {
    padding-left: 0;
    padding-right: 0;
  }
  .grid-thing {
    grid-template-columns: repeat(2, 1fr);
  } 
  .grid-thing.six {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-thing.two {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-thing.three {
    grid-template-columns: repeat(2, 1fr);
  }  
}

@media (max-width: 468px){
  .grid-thing.three,
  .grid-thing.two {
    grid-template-columns: repeat(1, 1fr);
  } 

}

