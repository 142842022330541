.product {
  background: #fff;
}

.product {
  li a[href="#applications"] {
    display: none;
  }
  h1 {
    font-size: 36px;
  }
  legend {
    text-align: center;
    font-size: 2.5em;
    color: $brand-color;
    font-family: $font__compact;
  }
  label {
    font-family: $font__compact;
    font-size: 1.25em;
    font-weight: 500;
  }
  .btn{
    margin-bottom: 10px;
  }
  .details {
    background: #fff;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 60px 25px 20px 25px;
  }
  .description {
    background: #fff;
    padding: 10px 5px;
    margin: 0 0 2em 0;
  }
  .description li {
    list-style: none;
    padding: 4px 0;
  }
  .description .price {
    font-size: 30px;
    padding-top: 20px;
  }
  .description .price-helper {
    text-transform: uppercase;
  }
  .contact-form {
    padding: 60px 0;
    background: #f0f0f0;
  }
  .form-control{
    border: 1px solid #555;
    border-radius: 0;
  }
  .form-control:focus {
    color: $brand-color;
    border-color: $brand-color;
    box-shadow: none;
  }
  .tab-pane img {
    margin: 20px 0;
  }
  // .tab-pane p,
  // .tab-pane h4 {
  //   margin: 20px 0;
  // }
  #addons h4 {
    text-transform: uppercase;
    color: $slate-blue;
  }
  .tab-pane h3 {
    color: $brand-color;
    font-weight: 100;
  }
  .tab-content small {
    font-size: 70%;
  }
  .well-orange {
    background-color: #fbebe6;
    color: $brand-color;
    border: 1px solid #fbebe6;
    border-radius: 10px;
  }
  .well-orange h5,
  .well-slate h5 {
    font-size: 22px;
    margin-top: 1em;
    margin-bottom: -10px;
  }
  .well-orange h3 {
    color: $brand-color;
    font-weight: 100;
  }
  .well-slate {
    background-color: #e0edff;
    color: #1c3452;
    border: 1px solid #e0edff;
    border-radius: 10px;
  }
  .well-slate h3 {
    color: #1c3452;
    font-weight: 100;
  }
  .description .fa,
  .details .fa {
    color: $brand-color;
  }
  .description .btn-brand .fa {
    color: #fff;
  }
  .tab-pane {
    min-height: 300px;
  }
  .tab-pane .faq  p,
  .tab-pane .faq h4 {
    text-transform: none;
    font-size: 18px;
  }
  .panel-group.faq h4 {
    padding: 6px 0 0;
    text-transform: none;
    font-size: 18px;
    font-weight: 500;
  }
  .panel-group.faq {
    margin-top: 10px;
  }
  .panel-default > .panel-heading {
    color: $dark-grey;
    background-color: $light-grey;
    border-color: $light-grey;
  }
  .panel-group .panel {
    border-radius: 0;
    margin-bottom: 6px;
  }
  .panel-default {
      border-color: $light-grey;
  }
  .panel-body li {
    font-size: 16px;
  }
  .faq-header {
    color: $brand-color;
  }
  .video {
    margin: 20px 0;
  }
  .invisible-fullwidth{
    height: 30px;
    background: #fff;
  }

}

.label-series {
  font-weight: 600;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid #fdede9;
  font-size: 16px;
  width: 100%;
  display: inline-block;
  text-align: center;
  color: $brand-color;
  text-transform: uppercase;
  background: #fdede9;
}
.label-series.label-redline {
  border-bottom: 1px solid $brand-redline;
  border-top: 1px solid $brand-redline;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  background: #fff;
  color: #000;
  padding-top: 9px;
}

@media (max-width: 414px){
  .product .cta {
    margin: 0;
    padding: 0;
  }
  .product h3{
    font-size: 20px;
  }
  .product h1 {
    font-size: 20px;
  }
  .description .price-helper {
    font-size: 12px;
  }
}

@media (max-width: 991px){
  .product .description li {
    text-align: center;
    list-style: none;
  }
  .product .description h3 {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .product .cta {
    padding-left: 30px;
  }
}
