// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------

footer.footer{
  min-height: 400px;
  background: #333;
  padding-top: 60px;
  padding-bottom: 10px;
  color: #fff;
  h3 {
    margin-bottom: 1em;
    color: $mid-grey;
    font-family: $font__compact;
    font-weight: 100;
    font-size: 30px;
  }
  h5 {
    color: $mid-grey;
  }
  p {
    font-family: $font__regular;
    font-size: 14px;
    font-weight: 400;
    color: $mid-grey;
    line-height: 1.5;
  }
  a {
    color: #ccc;
    text-decoration: none;
    line-height: 26px;
    &:hover,
    &:focus{
      color: $brand-color;
      text-decoration: none;
    }
  }
  ul{
    list-style: none;
    padding: 0;
  }
  input{
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
    background-color: #ccc;
    border: #ccc;
    color: $brand-color;
    font-size: 16px;
    padding-left: 10px;
  }
  input:focus{
    outline: 0;
    border: 1px solid $brand-color;
    background-color: #eee;
  }
  .item-group{
    margin: 20px 0;
  }
  .btn{
    height: 40px;
    font-size: 17px;
  }
  .cta a {
    color: $brand-color;
  }
  .copyright {
    padding-top: 5em;
    color: #7d7d7d;
    font-size: 12px;
    text-transform: uppercase;
  }
  .disclaimer {
    color: #7d7d7d;
    padding-bottom: 1.5em;
  }
  .legal {
    margin-top: 4em;
  }
}

.footer .social{
  margin-top: 20px;
  li{
    display: inline-block;
    margin-right: 8px;
  }
  a {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .footer{
    li, h3, h5, p,.social, .disclaimer {
      text-align: center;
    }
    .social li {
      display: inline-block;
      margin-right: 6px;
    }
    .social a {
      font-size: 28px;
    }
  }
}

@media (max-width: 768px) {
  .footer{
    li, h3, h5, p,.social, .disclaimer {
      text-align: center;
    }
    .social li {
      display: inline-block;
      margin-right: 8px;
    }
    .social a {
      font-size: 36px;
    }
  }
}

footer.legal {
  padding-top: 24px;
  background-color: #fff;
}
footer.legal p {
  text-transform: uppercase;
  font-size: 12px;
  color: #b0aeae;
}
footer.legal a {
  color: #b0aeae;
}