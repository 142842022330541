.brand-color{
  color: $brand-color;
}
.highlight {
  color: $brand-color;
}

.brand-redline {
  color: $brand-redline;
}

.bg-skewed {
  background: linear-gradient(
     -10deg,
     transparent 30%,
     $beach-sand 70%
     );
}
.bg-gray {
  background-color: #f5f5f5;
}
.bg-soft {
  background-color: #FFF9ED;
}
.bg-light{
  background-color: #f9f9fb;
}
.bg-ghost {
  background-color: ghostwhite;
}
.bg-smokey {
  background-color: whitesmoke;
}
.bg-beachy {
  background-color: $beach-sand;
}
.bg-skyblue {
  background-color: $baby-slate;
}
.bg-peachy {
  background-color: $baby-brand;
}
.bg-slate {
  background-color: $slate-blue;
}
.bg-dark {
    background: $slate-blue;
    color: $baby-slate;
}
.bg-cloudy {
    background: #dfe6e9;
}
.bg-space {
  background: #1d1d1d;
  color: #eaeaea;
}
.bg-red {
  background-color: #f3bfc4;
}
.bg-green {
  background-color: #c0d8bc;
}
.bg-yellow {
  background-color: #f7f3b2;
}
.sandy {
  background-color: $beach-sand-light;
}

.stormy {
  background-color: #f8f8f8;
}
.rounded-box {
  padding: 2em;
  border-radius: 15px;
}

.label-orange {
    background-color: $brand-color;
    color: $baby-brand;
}
.label-blue {
    background-color: $slate-blue;
    color: $baby-slate;
}
.text-yt {
  color: #fff;
}
.text-bb {
  color: $baby-slate;
}
.navbar-default.redline {
  border-bottom: 1px solid $brand-redline;
}
.label-series.redline {
  color: $brand-redline;
  background-color: $brand-redline-light;
}
ul.description.redline .fa {
  color: $brand-redline;
}